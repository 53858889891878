<template>
  <div class="st-container">
    <v-select
      v-model="selected"
      :options="destinationOptions"
      dir="rtl"
      :clearable="false"
      :placeholder="$t('search-tab.choose-destination')"
      @option:selecting="selecting"
      @search:focus="deselecting"
      class="style-chooser"
    >
    <template #selected-option="{ label }">
      <div style="display: flex; align-items: baseline;">
        <span style="margin-left:10px;">
          <!-- <img src="assets/img/location.png" alt=location> -->
          <i class="fa fa-map-marker-alt" aria-hidden="true"></i>
        </span>
        {{ label }}
      </div>
    </template>
    <template v-slot:option="{ label }">
      <span style="margin-left:10px;font-size:1.5rem;" class="item">
        <img src="assets/img/location.png" alt="location">
      </span>
      {{ label }}
    </template>
    </v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import TemplateSearchSelect from './TemplateSearchSelect';
import 'vue-select/dist/vue-select.css';

export default {
  name: 'SelectTheme1',
  mixins: [TemplateSearchSelect],
  components: {
    vSelect,
  },
};
</script>
<style scoped>
.st-container {
  overflow: visible;
}
</style>
<style>
input[type=search]{
  margin: 0;
  padding: 0;
  border: 0;
}
.vs__dropdown-toggle {
  background: #fff !important;
}
.st-container .vs--searchable .vs__dropdown-toggle {
  border-radius: 5px;
  padding: 0.575rem 1.35rem;
  height: auto;
}
.vs__selected{
  width: max-content;
}

.style-chooser .vs__dropdown-menu li {
  height: 45px;
  line-height: 35px;
  transition: 0.2s;
  z-index: 10;
}
.theme1 .style-chooser > ul > item{
  z-index: 99;
}

.vs__dropdown-option {
    color: #333 !important;
}

</style>
